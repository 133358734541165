
const state = () => ({
    leads: [],
    superleadDialog: false,
    superleadBase: {},
})

const mutations = {
    setLeadsData(state, value){
        state.leads = value;
    },
    setSuperleadDialog(state, value){
        state.superleadDialog = value;
    },
    setSuperleadBase(state, value){
        state.superleadBase = value;
    },
}

const actions = {
    SET_SUPERLEAD_AND_OPEN_DIALOG({ commit }, value) {
        commit("setSuperleadDialog", true);
        commit("setSuperleadBase", value);
    },
    SET_SUPERLEAD_BASE({ commit }, value) {
        commit("setSuperleadBase", value);
    },
    CLOSE_SUPERLEAD_DIALOG({commit}, value){
        commit("setSuperleadDialog", false);
        commit("setSuperleadBase", {});
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}