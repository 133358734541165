
const state = () => ({
    users: [],
    tests: [],
    user: {},
})

const mutations = {
    setUsersData(state, value){
        state.users = value;
    },
    setUserData(state, value){
        state.user = Object.assign({}, state.user, value)
    },
    setTestsData(state, value){
        state.tests = value
    },
    addUserData(state, value){
        state.users.push(value);
    },
    removeUserData(state, value){
        state.users = state.users.filter((e) => e.id !== value.deleted);
    },
    clearUser(state){
        state.user = {}
    }
}

const actions = {
    SET_USER_DATA({commit}, value){
        commit('clearUser', {})
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}