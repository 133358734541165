
export default [
    {
        path:'leads',
        redirect: {name: 'LeadsList'},
        meta: { breadCrumb: "leadsList", titleDocument: "leadsList", rights: ["lead/add", "lead/update", "lead/get"] },
        component: function () {
          return import(/* webpackChunkName: "leadsList" */ '../../modules/leads/views/Lead.vue')
        },
        children: [
            {
                path:'',
                name:'LeadsList',
                meta: { title:'LeadList', titleDocument: "leadsList", rights: ["lead/add", "lead/update", "lead/get"] },
                component: function () {
                    return import(/* webpackChunkName: "leadsList" */ '../../modules/leads/views/LeadList.vue')
                },
            },
        ]
      },
]