const state = () => ({
    brand: {},
})

const mutations = {
    setBrandData(state, value){
        state.brand = value;
    },
    resetData(state, payload){
        if(!payload.section){
            state.brand.styleConf = payload.value
        } else {
            state.brand.styleConf[payload.section] = payload.value
        }
        
    }
}

const actions = {
    RESET_DATA({ commit }, payload){
        commit("resetData", payload)
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}