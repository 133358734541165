import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//Pour ajouter des icones custom pour les utiliser avec vuetify
/*
1 - Importer l'icone de la librairie voulue (note: les icones ont le meme noms selon la lib, donc on les renomme a la volée)
    toutes les icones sont en pascalCase
*/

import {
    faSquareFacebook as fabSquareFacebook
} from '@fortawesome/free-brands-svg-icons'

import {
    faSackDollar as fasSackDollar,
    faSquare as fasSquare,
    faCircleXmark as fasCircleXmark,
    faCircleCheck as fasCircleCheck,
    faCircleQuestion as fasCircleQuestion,
    faPen as fasPen,
    faCircleInfo as fasCircleInfo,
    faCaretDown as fasCaretDown,
    faStar as fasStar,
    faStarHalfStroke as fasStarHalfStroke,
    faTriangleExclamation as fasTriangleExclamation,
    faCalendarXmark as fasCalendarXmark,
    faCalendarPlus as fasCalendarPlus,
    faLocationPen as fasLocationPen,
    faLocationXmark as fasLocationXmark,
    faGrid as fasGrid,
    faHouse as fasHouse,
    faMobileScreenButton as fasMobileScreenButton,
    faHeart as fasHeart,
    faCloudArrowDown as fasCloudArrowDown,
    faArrowRight as fasArrowRight,
    faUser as fasUser,
    faHandHoldingDollar as fasHandHoldingDollar,
    faBullseyePointer as fasBullseyePointer,
    faCircle as fasCircle,
    faCirclePlay as fasCirclePlay,
    faCaretUp as fasCaretUp,
    faCaretRight as fasCaretRight,
    faDown as fasDown,
    faUp as fasUp,
    faRight as fasRight,
    faLocationDot as fasLocationDot,
    faGripVertical as fasGripVertical,
    faAlarmClock as fasAlarmClock,
    faCalendarDays as fasCalendarDays,
    faPersonRunning as fasPersonRunning,
    faCalendarCheck as fasCalendarCheck,
    faEnvelope as fasEnvelope,
    faWhistle as fasWhistle,
    faBullhorn as fasBullhorn,
    faGear as fasGear,
    faHeadset as fasHeadset,
    faListCheck as fasListCheck,
    faShuffle as fasShuffle,
    faUserCheck as fasUserCheck,
    faCalendarStar as fasCalendarStar,
    faCommentSms as fasCommentSms,
    faMagnet as fasMagnet,
    faEnvelopeOpenText as fasEnvelopeOpenText,
    faMessageSmile as fasMessageSmile,
    faVoicemail as fasVoicemail,
    faBoxOpen as fasBoxOpen,
    faWandMagicSparkles as fasWandMagicSparkles,
    faDisplayCode as fasDisplayCode,
    faCheck as fasCheck,
    faXmark as fasXmark,
    faHeadphones as fasHeadphones,
    faPaperPlane as fasPaperPlane,
    faUsers as fasUsers,
    faHourglassEnd as fasHourglassEnd,
    faUserHelmetSafety as fasUserHelmetSafety,
    faDownload as fasDownload,
    faPrint as fasPrint,
    faPlayPause as fasPlayPause,
    faArrowsRotate as fasArrowsRotate,
    faTrophy as fasTrophy,
    faCalendar as fasCalendar,
    faCrown as fasCrown,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faPlus as farPlus,
    faClock as farClock,
    faCalendarDay as farCalendarDay,
    faStar as farStar,
    faAt as farAt,
    faMobile as farMobile,
    faPhone as farPhone,
    faCircleDot as farCircleDot,
    faCircle as farCircle,
    faRotate as farRotate,
    faMagnifyingGlassPlus as farMagnifyingGlassPlus,
    faArrowRightArrowLeft as farArrowRightArrowLeft,
    faClipboardList as farClipboardList,
    faCirclePause as farCirclePause,
    faThumbsUp as farThumbsUp,
    faThumbsDown as farThumbsDown,
    faPaintRoller as farPaintRoller,
    faScrewdriverWrench as farScrewdriverWrench,
    faUsers as farUsers,
    faCircleLeft as farCircleLeft,
    faCircleRight as faCircleRight,
    faCircleLeft as faCircleLeft,
    faDesktop as farDesktop,
    faMinus as farMinus,
    faBoxOpen as farBoxOpen,
    faShuffle as farShuffle,
    faPersonRunning as farPersonRunning,
    faUserPen as farUserPen,
    faWindow as farWindow,
    faWreathLaurel as farWreathLaurel,
} from '@fortawesome/pro-regular-svg-icons'
import {
    faBrush as falBrush,
    faUpRightAndDownLeftFromCenter as falUpRightAndDownLeftFromCenter,
    faFilter as falFilter,
    faBars as falBars,
    faStop as falStop,
    faSquareMinus as falSquareMinus,
    faSquareCheck as falSquareCheck,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faSort as falSort,
    faAt as falAt,
    faEnvelope as falEnvelope,
    faCommentDots as falCommentDots,
    faMagnifyingGlass as falMagnifyingGlass,
    faXmark as falXmark,
    faUnlock as falUnlock,
    faPhone as falPhone,
    faMapMarkerAlt as falMapMarker,
    faLock as falLock,
    faPencil as falPencil,
    faTrashCan as falTrashCan,
    faGear as falGear,
    faLink as falLink,
    faPlus as falPlus,
    faMobile as falMobile,
    faKey as falKey,
    faUserPen as falUserPen,
    faEye as falEye,
    faEyeSlash as falEyeSlash,
    faEllipsis as falEllipsis,
    faUserTie as falUserTie,
    faClipboardCheck as falClipboardCheck,
    faHeadset as falHeadSet,
    faMessage as falMessage,
    faFolderOpen as falFolderOpen,
    faAlarmClock as falAlarmClock,
    faClockRotateLeft as falClockRotateLeft,
    faBan as falBan,
    faBell as falBell,
    faArrowsUpDownLeftRight as falArrowsUpDownLeftRight,
    faCaretDown as falCaretDown,
    faUserGroup as falUserGroup,
    faPercent as falPercent,
    faFloppyDisk as falFloppyDisk,
    faHourglassHalf as falHourglassHalf,
    faCheck as falCheck,
    faTriangleExclamation as falTriangleExclamation,
    faChevronUp as falChevronUp,
    faChevronDown as falChevronDown,
    faChevronsLeft as falChevronsLeft,
    faChevronsRight as falChevronsRight,
    faPaperclip as falPaperclipt,
    faWhistle as falWhistle,
    faRightToBracket as falRightToBracket,
    faFlagCheckered as falFlagCheckered,
    faHandPointUp as falHandPointUp,
    faLinkSlash as falLinkSlash,
    faPaintRoller as falPaintRoller,
    faBullseyeArrow as falBullseyeArrow,
    faPhonePlus as falPhonePlus,
    faCommentLines as falCommentLines,
    faMessageSmile as falMessageSmile,
    faMicrophone as falMicrophone,
    faUsers as falUsers,
    faWrench as falWrench,
    faSteeringWheel as falSteeringWheel,
    faCircleInfo as falCircleInfo,
    faUser as falUser,
    faPaperPlane as falPaperPlane,
    faHeadset as falHeadset,
    faFolderPlus as falFolderPlus,
    faArrowPointer as falArrowPointer,
    faEnvelopeOpenText as falEnvelopeOpenText,
    faCalendar as falCalendar,
    faCalendarCheck as falCalendarCheck,
    faCalendarPlus as falCalendarPlus,
    faCalendarPen as falCalendarPen,
    faCalendarDay as falCalendarDay,
    faCalendarDays as falCalendarDays,
    faCopy as falCopy,
    faCircleMinus as falCircleMinus,
    faHandshake as falHandshake,
    faClock as falClock,
    faLocationDot as falLocationDot,
    faShelves as falShelves,
    faUserPlus as falUserPlus,
    faCalendarXmark as falCalendarXmark,
    faHeart as falHeart,
    faSackDollar as falSackDollar,
    faChartUser as falChartUser,
    faFaceAngry as falFaceAngry,
    faClipboardList as falClipboardList,
    faCirclePause as falCirclePause,
    faPhoneOffice as falPhoneOffice,
    faCircleDollar as falCircleDollar,
    faCameraWeb as falCameraWeb,
    faCameraWebSlash as falCameraWebSlash,
    faMagnet as falMagnet,
    faHelmetSafety as falHelmetSafety,
    faVoicemail as falVoicemail,
    faShuffle as falShuffle,
    faCrosshairs as falCrosshairs,
    faTicketSimple as falTicketSimple,
    faBoxOpen as falBoxOpen,
    faChartNetwork as flaChartNetwork,
    faHandHoldingDollar as falHandHoldingDollar,
    faArrowRightArrowLeft as falArrowRightArrowLeft,
    faBullhorn as falBullhorn,
    faPhoneSlash as falPhoneSlash,
    faPhoneRotary as falPhoneRotary,
    faList as falList,
    faChartPieSimple as falChartPieSimple,
    faRotateRight as falRotateRight,
    faMailboxFlagUp as falMailboxFlagUp,
    faWreathLaurel as falWreathLaurel,
} from '@fortawesome/pro-light-svg-icons'
import {
    faComments as fadComments,
    faHandBackPointUp as fadHandBackPointUp,
    faCloudExclamation as fadCloudExclamation,
    faTrashXmark as fadTrashXmark,
    faLeaf as fadLeaf,
    faPersonRays as fadPersonRays,
    faTrophy as fadTrophy,
    faEyes as fadEyes,
    faHeartPulse as fadHeartPulse,
    faCircleChevronRight as fadCircleChevronRight,
    faTurnUp as fadTurnUp,
    faChartLine as fadChartLine,
    faCommentDollar as fadCommentDollar,
    faPaperPlane as fadPaperPlane,
    faCalendarDay as fadCalendarDay,
    faIdCard as fadIdCard,
    faListCheck as fadListCheck,
    faIndustry as fadIndustry,
    faUsers as fadUsers,
    faUser as fadUser,
    faBookOpen as fadBookOpen,
    faPaintRoller as fadPaintRoller,
    faShuffle as fadShuffle,
    faEye as fadEye,
    faTrash as fadTrash,
    faPencilAlt as fadPencilAlt,
    faSortUp as fadSortUp,
    faCalendarDays as fadCalendarDays,
    faCommentsDollar as fadCommentsDollar,
    faEnvelopesBulk as fadEnvelopesBulk,
    faEnvelopeOpenText as fadEnvelopeOpenText,
    faHashtag as fadHashtag,
    faRobot as fadRobot,
    faCrosshairs as fadCrosshairs,
    faChartMixed as fadChartMixed,
    faLaptop as fadLaptop,
    faBook as fadBook,
    faBookUser as fadBookUser,
    faBell as fadBell,
    faBan as fadBan,
    faScrewdriverWrench as fadScrewdriverWrench,
    faShelves as fadShelves,
    faPenPaintbrush as fadPenPaintbrush,
    faCopyright as fadCopyright,
    faHouse as fadHouse,
    faHourglassHalf as fadHourglassHalf,
    faAt as fadAt,
    faCommentLines as fadCommentLines,
    faArrowUpRightFromSquare as fadArrowUpRightFromSquare,
    faMessageSmile as fadMessageSmile,
    faScroll as fadScroll,
    faVoicemail as fadVoicemail,
    faCodeBranch as fadCodeBranch,
    faStar as fadStar,
    faUserPlus as fadUserPlus,
    faUsersGear as fadUsersGear,
    faStore as fadStore,
    faRegistered as fadRegistered,
    faBrowser as fadBrowser,
    faBullhorn as fadBullhorn,
    faBullseyeArrow as fadBullseyeArrow,
    faAddressBook as fadAddressBook,
    faFlagCheckered as fadFlagCheckered,
    faRocketLaunch as fadRocketLaunch,
    faMobile as fadMobile,
    faCalendarCheck as fadCalendarCheck,
    faCartShopping as fadCartShopping,
    faRightLeft as fadRightLeft,
    faTriangleExclamation as fadTriangleExclamation,
    faMessageLines as fadMessageLines,
    faWhistle as fadWhistle,
    faWandMagicSparkles as fadWandMagicSparkles,
    faHeadset as fadHeadset,
    faPhone as fadPhone,
    faBoxOpen as fadBoxOpen,
    faAlarmClock as fadAlarmClock,
    faPhoneRotary as fadPhoneRotary,
    faMapPin as fadMapPin,
    faDoorOpen as fadDoorOpen,
    faDoorClosed as fadDoorClosed,
    faClock as fadClock,
    faUserGroup as fadUserGroup,
    faUserTie as fadUserTie,
    faCalendarPlus as fadCalendarPlus,
    faThumbsUp as fadThumbsUp,
    faThumbsDown as fadThumbsDown,
    faLink as fadLink,
    faHandHoldingDollar as fadHandHoldingDollar,
    faCirclePlus as fadCirclePlus,
    faSackDollar as fadSackDollar,
    faChartUser as fadChartUser,
    faCirclePause as fadCirclePause,
    faUserCheck as fadUserCheck,
    faHandshake as fadHandshake,
    faCameraWeb as fadCameraWeb,
    faGear as fadGear,
    faTrashCan as fadTrashCan,
    faCopy as fadCopy,
    faPencil as fadPencil,
    faKey as fadKey,
    faEnvelope as fadEnvelope,
    faEllipsisVertical as fadEllipsisVertical,
    faPersonRunning as fadPersonRunning,
    faCircleMinus as fadCircleMinus,
    faCalendarXmark as fadCalendarXmark,
    faPollPeople as fadPollPeople,
    faCircleInfo as fadCircleInfo,
    faChartPie as fadChartPie,
    faTable as fadTable,
    faChartBar as fadChartBar,
    faArrowDownWideShort as fadArrowDownWideShort,
    faAbacus as fadAbacus,
    faTicket as fadTicket,
    faLocationDot as fadLocationDot,
    faSpinner as fadSpinner,
    faFileInvoice as fadFileInvoice,
    faBasketShopping as fadBasketShopping,
    faChartNetwork as fadChartNetwork,
    faUserXmark as fadUserXmark,
    faCircleChevronDown as fadCircleChevronDown,
    faCode as fadCode,
    faBracketsCurly as fadBracketsCurly,
    faCloudArrowDown as fadCloudArrowDown,
    faUserHelmetSafety as fadUserHelmetSafety,
    faMagnifyingGlass as fadMagnifyingGlass,
    faRotateRight as fadRotateRight,
    faNetworkWired as fadNetworkWired,
    faTimes as fadTimes,
    faBellPlus as fadBellPlus,
    faBellOn as fadBellOn,
    faClockRotateLeft as fadClockRotateLeft,
    faBooks as fadBooks,
    faGears as fadGears,
    faCarSide as fadCarSide,
    faMotorcycle as fadMotorcycle,
    faRv as fadRv,
    faTractor as fadTractor,
    faTruck as fadTruck,
    faSailboat as fadSailboat,
    faDumbbell as fadDumbbell,
    faTricycleAdult as fadTricycleAdult,
    faMailboxFlagUp as fadMailboxFlagUp,
    faComment as fadComment,
    faPaperclip as fadPaperclip,
    faHandPointer as fadHandPointer,
    faFile as fadFile,
    faWreathLaurel as fadWreathLaurel,
    faFolder as fadFolder,
    faFolderOpen as fadFolderOpen,
    faLock as fadLock,
    faUnlock as fadUnlock,
    faPenToSquare as fadPenToSquare,
    faFolderPlus as fadFolderPlus,
    faCloudArrowUp as fadCloudArrowUp,
    faTrashArrowUp as fadTrashArrowUp,
    faUserSecret as fadUserSecret,

} from '@fortawesome/pro-duotone-svg-icons'


/*
2 - On ajoute l'icone a la librairie
*/

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(
    fadBookOpen, fadPaintRoller, fadCalendarDay, fasTriangleExclamation,
    fadChartLine, fadCommentDollar, fadPaperPlane, fadArrowUpRightFromSquare,
    fadIdCard, fadListCheck, fadIndustry, fadUsers, fadUser, fadShuffle, falCommentDots,
    falBars, falStop, falSquareMinus, falSquareCheck, fasCaretDown, falChevronLeft,
    falChevronRight, falSort, falAt, falEnvelope, fasSquare, falMagnifyingGlass,
    falXmark, fadTurnUp, fadGear, fasCircleXmark, fasCircleCheck, fasCircleQuestion, farClock,
    fasCircleInfo, farPlus, fasPen, fadTrash, farCalendarDay, falFilter, fadEye, falUnlock,
    fasStar, farStar, fasStarHalfStroke, fadPencilAlt, falPhone, falMapMarker,
    farAt, farMobile, farPhone, falLock, falPencil, falTrashCan, falGear, falLink, falPlus, falMobile,
    falKey, falUserPen, falEye, falEyeSlash, falEllipsis, falUserTie, falClipboardCheck, falHeadSet,
    falMessage, falFolderOpen, falAlarmClock, falClockRotateLeft, falBan, falBell, falArrowsUpDownLeftRight, falCaretDown, falUserGroup,
    falPercent, falFloppyDisk, falHourglassHalf, falCaretDown, falCheck, falTriangleExclamation, falChevronUp, falChevronDown,
    falChevronsLeft, falChevronsRight, fadSortUp, falRightToBracket, falPaperclipt, falWhistle, falFlagCheckered,
    falHandPointUp, falLinkSlash, falPaintRoller, falBullseyeArrow, falPhonePlus, falCommentLines, falMessageSmile,
    falMicrophone, fabSquareFacebook, falUsers, falWrench, falSteeringWheel, falCircleInfo, fadCircleChevronRight,
    falUser, falPaperPlane, falHeadset, falFolderPlus, falArrowPointer, falEnvelopeOpenText,
    falCalendar, falCalendarCheck, falCalendarPlus, falCalendarPen, falCalendarDay, falCalendarDays, fasCalendarXmark, fasCalendarPlus, falCopy,
    farCircleDot, farCircle, farRotate, farArrowRightArrowLeft, farMagnifyingGlassPlus, falUpRightAndDownLeftFromCenter, fasLocationXmark, fasLocationPen,
    farClipboardList, farCirclePause, farThumbsUp, farThumbsDown, fasGrid, farScrewdriverWrench, farPaintRoller, farUsers, farCircleLeft,
    fadCalendarDays, fadCommentsDollar, fadEnvelopesBulk, fadEnvelopeOpenText, fadHashtag, fadRobot, fadCrosshairs, falCrosshairs, fadChartMixed, fadLaptop,
    fadBook, fadBookUser, fadBell, fadBan, fadScrewdriverWrench, fadShelves, falCircleMinus, fasHouse, faCircleRight, faCircleLeft,
    fadPenPaintbrush, falHandshake, falClock, falLocationDot, falShelves, falUserPlus, falCalendarXmark, falHeart,
    farDesktop, fasMobileScreenButton, fasHeart, farMinus, fasCloudArrowDown, falSackDollar, falChartUser, falFaceAngry, falClipboardList,
    falCirclePause, falPhoneOffice, fasArrowRight, falCircleDollar, falCameraWeb, falCameraWebSlash, falMagnet, fasUser, falHelmetSafety, farBoxOpen, falBoxOpen, falVoicemail,
    falShuffle, farShuffle, farPersonRunning, falTicketSimple, fasHandHoldingDollar, flaChartNetwork, fasBullseyePointer, fadCopyright, fadHouse, fasCircle, fasCirclePlay,
    falHandHoldingDollar, falArrowRightArrowLeft, falBrush, falBullhorn, falPhoneSlash, falPhoneRotary, fadHourglassHalf, fadAt, fadCommentLines, fadMessageSmile, fadScroll, fadVoicemail, fadCodeBranch,
    fadStar, falList, falChartPieSimple, falRotateRight, fadUserPlus, fadUsersGear, fadStore, fadRegistered, fadBrowser, fadBullhorn, fadAddressBook, fadBullseyeArrow, fadFlagCheckered, fadRocketLaunch,
    fadMobile, fadCalendarCheck, fadCartShopping, fadRightLeft, fadTriangleExclamation, fadMessageLines, fadWhistle, fadWandMagicSparkles, fadHeadset, fadPhone, fadBoxOpen, fadAlarmClock,
    fadPhoneRotary, fadMapPin, fadDoorOpen, fadDoorClosed, fadClock, fadUserGroup, fadUserTie, fadCalendarPlus, fadThumbsUp, fadThumbsDown, fadLink, fadHandHoldingDollar, fadCirclePlus, fadSackDollar, fadChartUser, fadGear,
    fadCirclePause, fadUserCheck, fadHandshake, fadCameraWeb, fadTrashCan, fadCopy, fadPencil, fadKey, fadEnvelope, fadEllipsisVertical, fadPersonRunning, fadCircleMinus, fadCalendarXmark, fadPollPeople, fadCircleInfo, fadChartPie,
    fasCaretUp, fasCaretRight, fadTable, fadChartBar, fadArrowDownWideShort, fadAbacus, fasDown, fasUp, fasRight, fasLocationDot, fadTicket, farUserPen,
    fasGripVertical, fadLocationDot, fadSpinner, fadFileInvoice, fadBasketShopping, fadChartNetwork, fadCircleChevronDown, fadUserXmark, fadCode, fadBracketsCurly, fadCloudArrowDown, fadUserHelmetSafety,
    fasAlarmClock, fasCalendarDays, fasPersonRunning, fasCalendarCheck, fasEnvelope, fasWhistle, fasBullhorn, fasGear, fasHeadset, fasListCheck, fasShuffle, fasUserCheck, fadMagnifyingGlass, fadRotateRight, fadNetworkWired, fadTimes,
    fasCalendarStar, fadClockRotateLeft, fadBellPlus, fadBellOn, fadBooks, fadGears, fasCommentSms, fasMagnet, fasEnvelopeOpenText,
    fasMessageSmile, fasVoicemail, fasBoxOpen, fasWandMagicSparkles, fasDisplayCode, farWindow, fasCheck, fasXmark, fasHeadphones, fasPaperPlane, fasUsers, fasHourglassEnd, fasUserHelmetSafety,
    fasDownload, fadCarSide, fadMotorcycle, fadRv, fadTractor, fadTruck, fadSailboat, fasStar, fadDumbbell, fadTricycleAdult, fasPrint, fadMailboxFlagUp, fadComment, fadPaperclip, falMailboxFlagUp, fadHandPointer, fadFile, fasPlayPause, fasArrowsRotate, fadWreathLaurel, farWreathLaurel, falWreathLaurel, fasTrophy,
    fasCalendar, fasCrown, fadTrophy, fadLeaf, fadPersonRays, fadEyes, fadHeartPulse, fadComments, fadHandBackPointUp, fadCloudExclamation, fadTrashXmark, fadFolder, fadFolderOpen, fadLock, fadUnlock, fadPenToSquare,
    fadFolderPlus, fadCloudArrowUp, fasSackDollar, fadTrashArrowUp, fadUserSecret) // Include needed icons

/*
2 - On déclare les icones avec la clé utilisée par Vuetify ou une clé perso
*/
const CUSTOM_ICONS = {
    usersecret_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-secret']
        }
    },
    trasharrowup_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'trash-arrow-up']
        }
    },
    sackdollar_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sack-dollar']
        }
    },
    cloudup_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'cloud-arrow-up']
        }
    },
    folderplus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'folder-plus']
        }
    },
    pensquare_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'pen-to-square']
        }
    },
    lock_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'lock']
        }
    },
    unlock_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'unlock']
        }
    },
    folder_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'folder']
        }
    },
    folderopen_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'folder-open']
        }
    },
    comments_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'comments']
        }
    },
    handpointup_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'hand-back-point-up']
        }
    },
    cloud_excalamation_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'cloud-exclamation']
        }
    },
    trash_xmark_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'trash-xmark']
        }
    },
    heart_pulse_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'heart-pulse']
        }
    },
    eyes_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'eyes']
        }
    },
    trophy_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'trophy']
        }
    },
    leaf_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'leaf']
        }
    },
    personrays_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'person-rays']
        }
    },
    wreathlaurel_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'wreath-laurel']
        }
    },
    wreathlaurel_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'wreath-laurel']
        }
    },
    arrowsrotate_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrows-rotate']
        }
    },
    playpause_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'play-pause']
        }
    },
    tricyleadult_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'tricycle-adult']
        }
    },
    dumbbell_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'dumbbell']
        }
    },
    star_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'star']
        }
    },
    sailboat_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'sailboat']
        }
    },
    truck_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'truck']
        }
    },
    tractor_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'tractor']
        }
    },
    rv_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'rv']
        }
    },
    motorcycle_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'motorcycle']
        }
    },
    carside_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'car-side']
        }
    },
    download_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'download']
        }
    },
    user_helmet_safety_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-helmet-safety']
        }
    },
    hourglass_end_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'hourglass-end']
        }
    },
    users_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'users']
        }
    },
    paperplane_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'paper-plane']
        }
    },
    headphone_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'headphones']
        }
    },
    times_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'xmark']
        }
    },
    checkn_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'check']
        }
    },
    window_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'window']
        }
    },
    displaycode_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'display-code']
        }
    },
    magic_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'wand-magic-sparkles']
        }
    },
    boxopen_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'box-open']
        }
    },
    voicemail_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'voicemail']
        }
    },
    messagesmile_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'message-smile']
        }
    },
    enveloppeopen_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'envelope-open-text']
        }
    },
    magnet_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'magnet']
        }
    },
    sms_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'comment-sms']
        }
    },
    gears_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'gears']
        }
    },
    books_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'books']
        }
    },
    clockrotateleft_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'clock-rotate-left']
        }
    },
    chevroncircleright_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-chevron-right']
        }
    },
    calendarstar_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-star']
        }
    },
    networkwired_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'network-wired']
        }
    },
    usercheck_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-check']
        }
    },
    random_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'shuffle']
        }
    },
    tasks_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'list-check']
        }
    },
    headset_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'headset']
        }
    },
    settings_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'gear']
        }
    },
    print_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'print']
        }
    },
    bullhorn_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'bullhorn']
        }
    },
    whistle_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'whistle']
        }
    },
    envelope_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'envelope']
        }
    },
    calendarcheck_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-check']
        }
    },
    runner_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'person-running']
        }
    },
    calendar_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-days']
        }
    },
    alarm_clock_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'alarm-clock']
        }
    },
    bracketscurly_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'brackets-curly']
        }
    },
    chevroncircledown_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-chevron-down']
        }
    },
    chartnetwork_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-network']
        }
    },
    shoppingbasket_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'basket-shopping']
        }
    },
    fileinvoice_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'file-invoice']
        }
    },
    spinner_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'spinner']
        }
    },
    mapmarker_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'location-dot']
        }
    },
    gripvertical_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'grip-vertical']
        }
    },
    useredit_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'user-pen']
        }
    },
    arrowaltright_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'right']
        }
    },
    map_marker_alt_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'location-dot']
        }
    },
    arrowaltup_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'up']
        }
    },
    arrowaltdown_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'down']
        }
    },
    number_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'abacus']
        }
    },
    conversion_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'arrow-down-wide-short']
        }
    },
    chartbar_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-bar']
        }
    },
    table_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'table']
        }
    },
    caretdown_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-down']
        }
    },
    caretup_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-up']
        }
    },
    caretright_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-right']
        }
    },
    chartpie_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-pie']
        }
    },
    circleinfo_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-info']
        }
    },
    pollpeople_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'poll-people']
        }
    },
    calendartimes_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'calendar-xmark']
        }
    },
    circleminus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-minus']
        }
    },
    running_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'person-running']
        }
    },
    ellipsis_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'ellipsis-vertical']
        }
    },
    envelope_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'envelope']
        }
    },
    key_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'key']
        }
    },
    pencil_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'pencil']
        }
    },
    copy_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'copy']
        }
    },
    trashalt_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'trash-can']
        }
    },
    cog_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'gear']
        }
    },
    webcam_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'camera-web']
        }
    },
    handshake_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'handshake']
        }
    },
    usercheck_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-check']
        }
    },
    usercross_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-xmark']
        }
    },
    circleplus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-plus']
        }
    },
    handholdingusd_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'hand-holding-dollar']
        }
    },
    link_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'link']
        }
    },
    thumbs_up_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'thumbs-up']
        }
    },
    thumbs_down_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'thumbs-down']
        }
    },
    calendar_plus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'calendar-plus']
        }
    },
    inventory_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'shelves']
        }
    },
    usertie_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-tie']
        }
    },
    userfriends_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-group']
        }
    },
    clock_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'clock']
        }
    },
    dooropen_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'door-open']
        }
    },
    doorclosed_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'door-closed']
        }
    },
    mappin_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'map-pin']
        }
    },
    phoneoffice_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'phone-rotary']
        }
    },
    alarm_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'alarm-clock']
        }
    },
    boxopen_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'box-open']
        }
    },
    phone_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'phone']
        }
    },
    headset_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'headset']
        }
    },
    magic_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'wand-magic-sparkles']
        }
    },
    whistle_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'whistle']
        }
    },
    commentaltlines_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'message-lines']
        }
    },

    warning_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'triangle-exclamation']
        }
    },
    switch_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'right-left']
        }
    },
    mobile_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'mobile']
        }
    },
    calendar_check_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'calendar-check']
        }
    },
    cart_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'cart-shopping']
        }
    },
    rocket_launch_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'rocket-launch']
        }
    },
    flag_checkered_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'flag-checkered']
        }
    },
    address_book_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'address-book']
        }
    },
    bullseye_arrow_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'bullseye-arrow']
        }
    },
    bullhorn_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'bullhorn']
        }
    },
    browser_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'browser']
        }
    },
    registered_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'registered']
        }
    },
    store_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'store']
        }
    },
    users_cog_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'users-gear']
        }
    },
    star_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'star']
        }
    },
    user_plus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-plus']
        }
    },
    codebranch_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'code-branch']
        }
    },
    voicemail_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'voicemail']
        }
    },
    commentaltsmile_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'message-smile']
        }
    },
    scroll_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'scroll']
        }
    },
    externallink_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'arrow-up-right-from-square']
        }
    },
    commentlines_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'comment-lines']
        }
    },
    at_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'at']
        }
    },
    hourglass_half_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'hourglass-half']
        }
    },
    ticket_alt_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'ticket']
        }
    },
    phoneslash_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'phone-slash']
        }
    },
    bullhorn_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'bullhorn']
        }
    },
    brush_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'brush']
        }
    },
    handholdingusd_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'hand-holding-dollar']
        }
    },
    exchange_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'arrow-right-arrow-left']
        }
    },
    playcircle_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle-play']
        }
    },
    circle_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle']
        }
    },
    home_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'house']
        }
    },
    copyright_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'copyright']
        }
    },
    bullseyepointer_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'bullseye-pointer']
        }
    },
    handholdingusd_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'hand-holding-dollar']
        }
    },
    ticket_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'ticket-simple']
        }
    },
    running_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'person-running']
        }
    },
    random_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'shuffle']
        }
    },
    random_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'shuffle']
        }
    },
    voicemail_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'voicemail']
        }
    },
    box_open_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'box-open']
        }
    },
    box_open_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'box-open']
        }
    },
    chart_network_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chart-network']
        }
    },
    hard_hat_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'helmet-safety']
        }
    },
    user_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user']
        }
    },
    magnet_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'magnet']
        }
    },
    list_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'list']
        }
    },
    chart_pie_alt_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chart-pie-simple']
        }
    },
    redo_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'rotate-right']
        }
    },
    redo_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'rotate-right']
        }
    },
    webcam_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'camera-web']
        }
    },
    webcam_slash_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'camera-web-slash']
        }
    },
    usd_circle_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle-dollar']
        }
    },
    arrowright_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-right']
        }
    },
    phoneoffice_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'phone-rotary']
        }
    },
    pausecircle_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle-pause']
        }
    },
    clipboardlist_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'clipboard-list']
        }
    },
    angry_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'face-angry']
        }
    },
    userchart_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chart-user']
        }
    },
    sackdollar_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'sack-dollar']
        }
    },
    cloud_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'cloud-arrow-down']
        }
    },
    minus_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'minus']
        }
    },
    mobile_alt_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'mobile-screen-button']
        }
    },
    heart_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'heart']
        }
    },
    desktop_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'desktop']
        }
    },
    calendar_times_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-xmark']
        }
    },
    heart_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'heart']
        }
    },
    clock_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'clock']
        }
    },
    map_marker_alt_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'location-dot']
        }
    },
    inventory_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'shelves']
        }
    },
    user_plus_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'user-plus']
        }
    },
    handshake_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'handshake']
        }
    },
    pencil_paintbrush_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'pen-paintbrush']
        }
    },
    arrow_alt_circle_right_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-right']
        }
    },
    arrow_alt_circle_left_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-left']
        }
    },
    home_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fa', 'house']
        }
    },
    minus_circle_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle-minus']
        }
    },
    inventory_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'inventory']
        }
    },
    tools_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'screwdriver-wrench']
        }
    },
    ban_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'ban']
        }
    },
    bell_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'bell']
        }
    },
    book_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'book']
        }
    },
    bookuser_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'book-user']
        }
    },
    laptop_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'laptop']
        }
    },
    analytics_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-mixed']
        }
    },
    crosshairs_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'crosshairs']
        }
    },
    crosshairs_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'crosshairs']
        }
    },
    robot_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'robot']
        }
    },
    hashtag_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'hashtag']
        }
    },
    envelope_open_text_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'envelope-open-text']
        }
    },
    mailbulk_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'envelopes-bulk']
        }
    },
    commentsdollar_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'comments-dollar']
        }
    },
    calendar_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'calendar-days']
        }
    },
    arrow_circle_left_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-left']
        }
    },
    users_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'users']
        }
    },
    tools_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'screwdriver-wrench']
        }
    },
    paintroller_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'paint-roller']
        }
    },
    th_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fa', 'grid']
        }
    },
    clipboard_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'clipboard-list']
        }
    },
    pause_circle_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-pause']
        }
    },
    thumbs_up_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'thumbs-up']
        }
    },
    thumbs_down_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'thumbs-down']
        }
    },
    expand_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'up-right-and-down-left-from-center']
        }
    },
    switch_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'arrow-right-arrow-left']
        }
    },
    search_plus_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'magnifying-glass-plus']
        }
    },
    search_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'magnifying-glass']
        }
    },
    refresh_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'rotate']
        }
    },
    copy_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'copy']
        }
    },
    facebook: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fab', 'square-facebook']
        }
    },
    info_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle-info']
        }
    },
    steeringwheel_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'steering-wheel']
        }
    },
    wrench_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'wrench']
        }
    },
    users_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'users']
        }
    },
    microphone_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'microphone']
        }
    },
    commentsmile_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'message-smile']
        }
    },
    commentlines_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'comment-lines']
        }
    },
    phoneplus_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'phone-plus']
        }
    },
    target_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'bullseye-arrow']
        }
    },
    paintroller_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'paint-roller']
        }
    },
    handup_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'hand-point-up']
        }
    },
    flag_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'flag-checkered']
        }
    },
    whistle_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'whistle']
        }
    },
    warning_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'triangle-exclamation']
        }
    },
    //ICONES LIGHT
    check_l: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'check']
        }
    },
    complete: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'check']
        }
    },
    checkboxOn: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'square-check']
        }
    },
    checkboxOff: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'stop']
        }
    },
    checkboxIndeterminate: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'square-minus']
        }
    },
    menu: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'bars']
        }
    },
    unfold: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'sort']
        }
    },
    next: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-right']
        }
    },
    prev: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-left']
        }
    },
    up_l: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-up']
        }
    },
    down_l: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-down']
        }
    },
    doubleleft_l: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevrons-left']
        }
    },
    doubleright_l: { //Used by Vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevrons-right']
        }
    },
    expand: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'caret-down']
        }
    },
    hourglass_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'hourglass-half']
        }
    },
    magnify_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'magnifying-glass']
        }
    },
    filter_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'filter']
        }
    },
    map_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'location-dot']
        }
    },
    phone_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'phone']
        }
    },
    comment_dots_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'comment-dots']
        }
    },
    at_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'at']
        }
    },
    lock_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'lock']
        }
    },
    unlock_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'unlock']
        }
    },
    envelope_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'envelope']
        }
    },
    close: { //Used by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'xmark']
        }
    },
    clear: { //Used by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'xmark']
        }
    },
    pencil_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'pencil']
        }
    },
    trash_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'trash-can']
        }
    },
    setting_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'gear']
        }
    },
    link_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'link']
        }
    },
    unlink_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'link-slash']
        }
    },
    plus_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'plus']
        }
    },
    mobile_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'mobile']
        }
    },
    key_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'key']
        }
    },
    useredit_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'user-pen']
        }
    },
    eye_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'eye']
        }
    },
    eye_slash_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'eye-slash']
        }
    },
    dots_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'ellipsis']
        }
    },
    user_tie_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'user-tie']
        }
    },
    clipboard_check_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'clipboard-check']
        }
    },
    comment_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'message']
        }
    },
    folder_open_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'folder-open']
        }
    },
    alarm_clock_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'alarm-clock']
        }
    },
    history_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'clock-rotate-left']
        }
    },
    ban_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'ban']
        }
    },
    bell_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'bell']
        }
    },
    arrows_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'arrows-up-down-left-right']
        }
    },
    dropdown_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'caret-down']
        }
    },
    user_friends_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'user-group']
        }
    },
    percent_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'percent']
        }
    },
    save_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'floppy-disk']
        }
    },
    signInAlt_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'right-to-bracket']
        }
    },
    paperclip_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'paperclip']
        }
    },
    user_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'user']
        }
    },
    paper_plane_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'paper-plane']
        }
    },
    headset_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'headset']
        }
    },
    folder_plus_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'folder-plus']
        }
    },
    mouse_pointer_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'arrow-pointer']
        }
    },
    envelope_open_text_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'envelope-open-text']
        }
    },
    calendar_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar']
        }
    },
    calendar_check_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-check']
        }
    },
    calendar_plus_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-plus']
        }
    },
    calendar_edit_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-pen']
        }
    },
    calendar_day_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-day']
        }
    },
    calendar_alt_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'calendar-days']
        }
    },

    //ICONES SOLIDES
    square_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'square']
        }
    },
    warning_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'triangle-exclamation']
        }
    },
    dropdown: { //Used by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-down']
        }
    },
    delete: { //Used by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle-xmark']
        }
    },
    check_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle-check']
        }
    },
    question_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle-question']
        }
    },
    info_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'circle-info']
        }
    },

    pencil_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'pen']
        }
    },
    calendartimes_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-xmark']
        }
    },
    calendarplus_s: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-plus']
        }
    },
    fullstar_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'star']
        }
    },

    halfstar_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'star-half-stroke']
        }
    },

    //Icones Regular
    emptystar_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'star']
        }
    },
    at_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'at']
        }
    },
    mobile_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'mobile']
        }
    },
    phone_r: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'phone']
        }
    },
    calendar_r: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'calendar-day']
        }
    },
    clock_r: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'clock']
        }
    },
    plus_r: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'plus']
        }
    },
    dot_circle_r: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-dot']
        }
    },
    circle_r: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle']
        }
    },
    radioOn: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle-dot']
        }
    },
    radioOff: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle']
        }
    },
    //ICONES DUOTONE
    settings_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'gear']
        }
    },
    return_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'turn-up']
        }
    },
    pencil_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'pencil-alt']
        }
    },
    dashboard_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-line']
        }
    },
    operations_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'comment-dollar']
        }
    },
    leads_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'shuffle']
        }
    },
    campaign_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'paper-plane']
        }
    },
    agenda_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'calendar-day']
        }
    },
    contacts_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'id-card']
        }
    },
    actions_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'list-check']
        }
    },
    sales_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'industry']
        }
    },
    users_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'users']
        }
    },
    user_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user']
        }
    },
    catalogue_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'book-open']
        }
    },
    templates_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'paint-roller']
        }
    },
    trash_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'trash']
        }
    },
    eye_d: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'eye']
        }
    },
    sort: { // used for the nav-icon by vuetify
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'sort-up']
        }
    },
    sackdollar_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'sack-dollar']
        }
    },
    userchart_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'chart-user']
        }
    },
    pausecircle_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'circle-pause']
        }
    },
    map_marker_time_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'location-xmark']
        }
    },
    map_marker_edit_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'location-pen']
        }
    },
    code_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'code']
        }
    },
    cloud_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'cloud-arrow-down']
        }
    },
    user_hard_hat_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'user-helmet-safety']
        }
    },
    times_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'times']
        }
    },
    bell_plus_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'bell-plus']
        }
    },
    bell_on_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'bell-on']
        }
    },
    mailbox_flag_up_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'mailbox-flag-up']
        }
    },
    comment_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'comment']
        }
    },
    paperclip_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'paperclip']
        }
    },
    mailbox_flag_up_l: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'mailbox-flag-up']
        }
    },
    hand_pointer_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'hand-pointer']
        }
    },
    file_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'file']
        }
    },
    wreath_laurel_d: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fad', 'wreath-laurel']
        }
    },
    trophy_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'trophy']
        }
    },
    calendar_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar']
        }
    },
    crown_s: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'crown']
        }
    },
}

export { CUSTOM_ICONS }
