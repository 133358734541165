import GenericDataService from '@/services/GenericDataService'
import vuetify from '@/plugins/vuetify'

const state = () => ({
    openIndispoDialog: false,
    date_start: null
})

const mutations = {
    SetOpenIndispoUser(state, value){
        state.openIndispoDialog = value
    },
    setStartDateAgenda(state, value){
        state.date_start = value
    }
}

const actions = {
    SET_OPEN_INDISPO_USER({commit}, value){
        commit('SetOpenIndispoUser', value)
    },
    SET_STARTDATE_AGENDA({commit}, value){
        commit('setStartDateAgenda', value)
    },
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}