import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import axiosHttp from './http-common'
import store from './store'

Vue.use(VueI18n)

const loadedLanguages = []

export const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLanguageAsync('fr'),
  missing: (locale, key, vm, values) => {
    let payload = {
      key: key,
      lang: locale,
      url: document.location.pathname
    }
    axiosHttp.http.post(`/ns/i18n/addNewKey`, payload).then(response => {
      console.log("trad_manquante", response)
    })
    return key
  },
})


Vue.prototype.$t = function (key) {
  //let key2 = key.toLowercase();
  var values = [], len = arguments.length - 1;
  while ( len-- > 0 ) values[ len ] = arguments[ len + 1 ];

  var i18n = this.$i18n;
  return i18n._t.apply(i18n, [ typeof(key) == 'string' ? key.toLowerCase() : key, i18n.locale, i18n._getMessages(), this ].concat( values ))
};

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  //store.dispatch('settings/SET_PARAM', {key: 'lang', data: `${lang}`}).then((e) => {
    return lang
  //})
}



export function loadLanguageAsync (lang, otherMsgs = {}) {
  if (loadedLanguages.includes(lang) && otherMsgs === {}) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  axiosHttp.http.get(`/ns/i18n/get/${lang}`).then(response => {
      let msgs = response.data.data
      loadedLanguages.push(lang)
      i18n.setLocaleMessage(lang, {...msgs, ...otherMsgs[lang]})
      setI18nLanguage(lang)
       Promise.resolve()
  })
}


// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
