const state = () => ({
    website: {},
})

const mutations = {
    setWebsiteData(state, value){
        state.website = value;
    },
}

const actions = {
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}