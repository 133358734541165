
const state = () => ({
    segmentations: [],
    segmentation: [],
    schema: {},
    references: {},
})

const mutations = {
    setSegmentationsData(state, value){
        state.segmentations = value;
    },
    setSegmentationData(state, value){
        state.segmentation = value;
    },
    setSchemaData(state, value){
        state.schema = value
    },
    setReferencesData(state, value){
        state.references = value
    }
}

const actions = {
    SET_SEGMENTATION_DATA({ commit }, value) {
        commit("setSegmentationData", value)
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}