<template>
    <v-dialog v-model="openErrorDialog" width="500" color="error" class="errorDialog">
        <v-card>
            <v-card-title>
                <h4 class="error--text icon-title errorTitle">
                    <b>{{$t('ErrorMessage')}}</b>
                    <v-btn text class="square" @click="openErrorDialog = false"><v-icon small>$close</v-icon></v-btn>
                </h4>
            </v-card-title>
            <v-card-text>
                <span v-if="$store.state.errorObject.errorMessage && $store.state.errorObject.errorMessage.hasOwnProperty('text')">
                    {{$t($store.state.errorObject.errorMessage.text, { num : ($store.state.errorObject.errorMessage.id), module : $store.state.errorObject.errorMessage.module })}}
                </span>
                <span v-else>
                    {{$t($store.state.errorObject.errorMessage)}}
                </span>
                
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:"ErrorDialogComponent",
    data() {
        return {
            
        }
    },
    computed: {
        openErrorDialog: { 
            // getter
            get: function () {
                return this.$store.state.errorDialog
            },
            // setter
            set: function (newValue) {
                this.$store.dispatch('REMOVE_ERROR', {})
            }
        }
    },
}
</script>
<style lang="scss">
    .errorDialog {
        z-index: 1000;
    }
    .errorTitle {
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
</style>{"key":{"text":"idNotExistOrNotAllowed","id":"\"450\"","module":"RetroplanningModule"},"lang":"fr","url":"/todos/"}