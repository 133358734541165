const state = () => ({
    rolesAndPermissions: [],
    entityTypes: [],
    jobs: [],
    univers: [],
    cancelReasons: [],
    requestStatus: [],
})

const mutations = {
    setRolesAndPermissions(state, value){
        state.rolesAndPermissions = value;
    },
    setEntityTypesData(state, value){
        state.entityTypes = value;
    },
    addEntityTypeData(state, value){
        state.entityTypes.data.push(value);
    },
    setJobsData(state, value){
        state.jobs = value;
    },
    setUniversData(state, value){
        state.univers = value;
    },
    setCancelReasonsData(state, value){
        state.cancelReasons = value;
    },
    setRequestStatusData(state, value){
        state.requestStatus = value;
    },
}

const actions = {
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}