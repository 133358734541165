import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path:'retroplanning',
        meta: {breadCrumb: 'retroplanning', titleDocument: "theretroplanning", rights: ["retroplanning/menu"] },
        name:'retroplanning',
        redirect:{ name: 'retroplanningEmailList'},
        component: function () {
            return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/Campaign.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            })  
        },
        children: [
            {
                path:'',
                name:'retroplanningEmailList',
                meta: { title:'emailList', titleDocument: "theEmails" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignEmailList.vue')
                },
            },
            {
                path:'sms',
                name:'retroplanningSMSList',
                meta: { title:'smsList', titleDocument: "theSms" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignSMSList.vue')
                },
            },
            {
                path:'mms',
                name:'retroplanningMMSList',
                meta: { title:'mmsList', titleDocument: "theMms" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignMMSList.vue')
                },
            },
            {
                path:'vms',
                name:'retroplanningVMSList',
                meta: { title:'vmsList', titleDocument: "theVms" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignVMSList.vue')
                },
            },
            {
                path:'mailing',
                name:'retroplanningMailingList',
                meta: { title:'mailingList', titleDocument: "theMailings" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignMailingList.vue')
                },
            },
            {
                path:'call',
                name:'retroplanningCallList',
                meta: { title:'callList', titleDocument: "theCalls" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignCallList.vue')
                },
            },
            {
                path:'entrantes',
                name:'retroplanningInList',
                meta: { title:'RpInList', titleDocument: "theRpIn" },
                component: function () {
                    return import(/* webpackChunkName: "rpList" */ /* webpackPrefetch: true */'../../modules/campagnes/views/CampaignInList.vue')
                },
            },
            {
                path:':id',
                meta: { breadCrumb: "retroplanningDetails", titleDocument: "retroplanningDetails", title: "retroplanningDetails", subMenu: false, rights: ["retroplanning/get"] } ,
                name: 'RetroplanningDetail',
                component: function () {
                    return import(/* webpackChunkName: "retroplanningDetails" */ /* webpackPrefetch: true */'../../modules/campagnes/views/Campaign/CampaignDashboard.vue')
                },
                beforeEnter: (to, from, next) => {
                    store.dispatch('base/SET_SUB_MENU', []);
                    next();
                },
            }
        ]
    }
]