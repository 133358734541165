import GenericDataService from '@/services/GenericDataService'

const state = () => ({
    forms: {},
})

const mutations = {
    setFormData(state, value){
        var form = {
            reference: value.reference,
            base_model: Object.keys(value.data.model).length === 0 ? {} : _.cloneDeep(value.data.model),
            model: Object.keys(value.data.model).length === 0 ? {} : value.data.model,
            schema: value.data.schema,
            params: value.data.params
        }
        
        state.forms[value.reference] = form;
    },
    clearForm(state, value){
        delete state.forms[value];
    }
}

const actions = {
    async GET_FORM_DATA({commit, state}, payload) {
        try {
            const response = await GenericDataService.postData(payload.url, payload.payload);
            
            // console.log('-------------- GET FORM DATA --------------');
            // console.log(payload.url);
            // console.log(JSON.parse(JSON.stringify(response)));

            if(response.data.success){
                //let reference = Object.keys(state.forms).length;
                let reference = Date.now(); // J'ai changé car l'object keys lenght génère des bug quand on ouvre et ferme plusieurs forms
                commit('setFormData', {'data': response.data.data, 'reference': reference})
                return {reference: reference};
            }
        } catch (error) {
            //router.push({name: "Error404"})
            console.log(error)
        }
    },
    async CLEAR_FORM({commit}, payload){
        commit("clearForm", payload);
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
