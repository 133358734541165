import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path:'library',
        meta: { breadCrumb: 'library', titleDocument: "library", rights: ["library/menu"] },
        redirect:{ name: 'LibraryList'},
        component: function () {
            return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/library/views/Library.vue')
        },
        children: [
            {
                path:'/',
                name:'LibraryList',
                meta: { title:'LibraryList', titleDocument: "library", rights: ["library/menu"] },
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/library/views/LibraryList.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=library').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })  
                },
            },
        ]
    }
]