const state = () => ({
    openHistoryDrawer: false,
    apiUrl: "",
    history: {},
})

const mutations = {
    setOpenHistoryDrawer(state, value) {
        state.openHistoryDrawer = value;
    },
    setApiUrl(state, value){
        state.apiUrl = value;
    },
    setHistory(state, value){
        state.history = value;
    },
}


const actions = {
    SET_OPEN_HISTORY_DRAWER({ commit }, value) {
        commit("setOpenHistoryDrawer", value)
    },
    SET_API_URL({ commit }, value) {
        commit("setApiUrl", value)
    },
    SET_HISTORY({ commit }, value) {
        commit("setHistory", value)
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
