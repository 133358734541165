
export default [
    {
        path:'opportunities',
        redirect: {name: 'OpportunitiesList'},
        meta: { breadCrumb: "opportunitiesList", titleDocument: "opportunitiesList", rights: [] },
        component: function () {
          return import(/* webpackChunkName: "todosList" */ '../../modules/opportunities/views/Opportunities.vue')
        },
        children: [
            {
                path:'',
                name:'OpportunitiesList',
                meta: { title:'opportunitiesList', titleDocument: "opportunitiesList", rights: [] },
                component: function () {
                    return import(/* webpackChunkName: "todosList" */ '../../modules/opportunities/views/OpportunitiesList.vue')
                },
            },
        ]
      },
]