import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path: 'forms',
        redirect: { name: 'FormsList' },
        meta: { breadCrumb: "formsList", titleDocument: "formsList", rights: ["form/add", "form/update", "form/get"] },
        component: function () {
          return import(/* webpackChunkName: "leadsList" */ '../../modules/forms/views/Form.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            });               
        },
        children: [
            {
                path: '',
                name: 'FormsList',
                meta: { title:'FormList', titleDocument: "formsList", rights: ["form/add", "form/update", "form/get"] },
                component: function () {
                    return import(/* webpackChunkName: "formsList" */ '../../modules/forms/views/FormList.vue')
                },
            },
        ]
      },
]