
export default [
    {
        path:'todos',
        redirect: {name: 'TodosList'},
        meta: { breadCrumb: "todosList", titleDocument: "todoList", rights: [] },
        component: function () {
          return import(/* webpackChunkName: "todosList" */ '../../modules/todos/views/Todo.vue')
        },
        children: [
            {
                path:'',
                name:'TodosList',
                meta: { title:'TodoList', titleDocument: "todoList", rights: [] },
                component: function () {
                    return import(/* webpackChunkName: "todosList" */ '../../modules/todos/views/TodoList.vue')
                },
            },
        ]
      },
]