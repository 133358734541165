import store from '../../store';
import GenericDataService from '@/services/GenericDataService';

export default [
    {
        path:'missions',
        redirect: {name: 'MissionsList'},
        meta: { breadCrumb: "missionsList", titleDocument: "missionsList", rights: [] },
        component: function () {
            return import(/* webpackChunkName: "todosList" */ '../../modules/missions/views/Mission.vue')
        },
        children: [
            {
                path:'',
                name:'MissionsList',
                meta: { title:'MissionList', titleDocument: "missionsList", rights: ["mission/menu"] },
                component: function () {
                    return import(/* webpackChunkName: "missionsList" */ '../../modules/missions/views/MissionList.vue')
                },
            },
            {
                path:':mission_id',
                name: 'MissionDetail',
                redirect:{ name: 'MissionDashboard', titleDocument: "missionsDashboard" },
                component: function () {
                    return import(/* webpackChunkName: "entitiesDetail" */ '../../modules/missions/views/MissionDetail.vue')
                },
                children: [
                    {
                        path:'dashboard',
                        name:'MissionDashboard',
                        meta: { breadCrumb: "dashboard", title:"missionDetail", titleDocument: "missionsDashboard", rights: [] } ,
                        component: function () {
                            return import(/* webpackChunkName: "entitiesDashboard" */ '../../modules/missions/views/MissionDetailDashboard.vue')
                        },
                    },
                    {
                        path:'stakeholders',
                        name:'MissionStakeholders',
                        meta: { breadCrumb: "stakeholders", titleDocument: "missionStakeholders", title:"missionStakeholders", rights: [] } ,
                        component: function () {
                            return import(/* webpackChunkName: "entitiesStaff" */ '../../modules/missions/views/MissionDetailStakeholders.vue')
                        },
                    },
                    {
                        path:'contacts',
                        name:'MissionDetailContact',
                        meta: { breadCrumb: "contacts", titleDocument: "missionContacts", title:"MissionDetailContact", rights: [] } ,
                        redirect: {name: 'MissionDetailContactList'},
                        component: function () {
                            return import('../../modules/base/views/BaseEmpty.vue')
                        },
                        children: [
                            {
                                path:'',
                                name:'MissionDetailContactList',
                                meta: { title:'MissionDetailContactList', titleDocument: "missionContacts", rights: [] },
                                component: function () {
                                    return import('../../modules/missions/views/MissionDetailContactList.vue')
                                },
                            },
                            {
                                path:':contact_id',
                                meta: { breadCrumb: "contactDetails", titleDocument: "missionContactsDetail", title: "MissionContactDetail", subMenu: false, rights: [] },
                                name: 'MissionContactDetail',
                                component: function () {
                                    return import('../../modules/contacts/views/ContactDetail.vue')
                                }
                            },
                        ]
                    },
                ]
            }
        ]
    },
]