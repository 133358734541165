
const state = () => ({
    entities: [],
    entity: {},
})

const mutations = {
    setEntitiesData(state, value){
        state.entities = value;
    },
    setEntityData(state, value){
        state.entity = value;
    },
    addEntityData(state, value){
        state.entities.data.push(value);
    },
    removeEntityData(state, value){
        state.entities = state.entities.filter((e) => e.id !== value.deleted);
    },
    setHours(state, value){
        state.entity.hours = value
    }
}

const actions = {
    SET_HOURS({commit}, payload){
        commit('setHours', payload)
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}