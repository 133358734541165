
const state = () => ({
    templates: [],
    template: {}
})

const mutations = {
    setTemplatesData(state, value){
        state.templates = value;
    },
    setTemplateData(state, value){
        state.template = value;
    },
}

const actions = {
    RESET_TEMPLATE({ commit }, value){
        commit("setTemplateData", {})
    },
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}