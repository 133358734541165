
const state = () => ({
    missions: [],
    mission: {},
})

const mutations = {
    setMissionsData(state, value){
        state.missions = value;
    },
    setMissionData(state, value){
        state.mission = value;
    },
    addMissionData(state, value){
        state.missions.data.push(value);
    },
    removeMissionData(state, value){
        state.missions = state.missions.filter((e) => e.id !== value.deleted);
    }
}

const actions = {

}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}