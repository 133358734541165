import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path: '/statistics',
        meta: { breadCrumb: 'statistics', titleDocument: "statistics", subMenu: false},
        component: function () {
            return import(/* webpackChunkName: "form-operations" */ '../../modules/statistics/views/Statistics.vue')
        },
        children: [
            {
                path: '',
                name: "StatisticsList",
                meta: { title:'StatisticsList', breadCrumb: 'edit', titleDocument: "statisticsDetail", subMenu: false },
                component: function () {
                    return import(/* webpackChunkName: "template" */ '../../modules/statistics/views/StatisticsList.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })         
                },
            }
        ]
    }
]