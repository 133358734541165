import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path:'contacts',
        meta: {breadCrumb: 'contactlist', titleDocument: "theContacts" },
        redirect:{ name: 'ContactsList'},
        component: function () {
            return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/contacts/views/Contact.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=contact').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            })  
        },
        children: [
            {
                path:'',
                name:'ContactsList',
                meta: { title:'Contacts', titleDocument: "theContacts", rights: ["lead/contactMenu"] },
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/contacts/views/ContactList.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=contact').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })  
                },
            },
            {
                path: 'blacklist',
                name: 'BlacklistList',
                meta: { title:'blacklistlist', titleDocument: "blacklist", rights: ["blacklist/get"] },
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/blacklist/views/BlacklistList.vue')
                },
            },
            {
                path:'contacts-list',
                name:'ContactsListList',
                meta: { title:'contactlistlist', titleDocument: "contactList", rights: ["contactlist/add", "contactlist/update", "contactlist/delete"] },
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/contactslist/views/ContactsListList.vue')
                },
            },
            {
                path:'import-management',
                name:'ImportManagementList',
                meta: { title:'ImportManagement', titleDocument: "ImportManagement", rights: ["import/menu"] },
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/importmanagement/views/ImportManagementList.vue')
                },
            },
            {
                path:'websites',
                meta: { breadCrumb: 'website', titleDocument: "theWebsites", rights: ["website/add", "website/update", "website/delete"] },
                redirect:{ name: 'WebsiteList'},
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/website/views/Website.vue')
                },
                children: [
                    {
                        path:'/',
                        name:'WebsiteList',
                        meta: { title:'WebsiteList', titleDocument: "theWebsites", rights: ["website/get"] },
                        component: function () {
                            return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/website/views/WebsiteList.vue')
                        },
                        
                    },
                    {
                        path:':website_id',
                        meta: { breadCrumb: "websiteDetails", titleDocument: "websiteDetails", title: "websiteDetails", subMenu: false, rights: ["website/get"] } ,
                        name: 'WebsiteDetail',
                        component: function () {
                            return import(/* webpackChunkName: "contactsDetail" */ /* webpackPrefetch: true */'../../modules/website/views/WebsiteDetail.vue')
                        },
                        redirect:{name: 'WebsiteDashboard'},
                        children: [
                            {
                                path:'dashboard',
                                name:'WebsiteDashboard',
                                meta: { title:'WebsiteDashboard', titleDocument: "websiteDetails", rights: ["website/get"] },
                                component: function () {
                                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/website/views/WebsiteDashboard.vue')
                                },
                            }
                        ]
                    },
                ]
            },
            {
                path:':contact_id',
                meta: { breadCrumb: "contactDetails", titleDocument: "contactDetails", title: "contactDetails", subMenu: false, rights: ["contact/get"] } ,
                name: 'ContactDetail',
                component: function () {
                    return import(/* webpackChunkName: "contactsDetail" */ /* webpackPrefetch: true */'../../modules/contacts/views/ContactDetail.vue')
                },
                beforeEnter: (to, from, next) => {
                    store.dispatch('base/SET_SUB_MENU', []);
                    next();
                },
            }
        ]
    }
]