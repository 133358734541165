<template>
    <v-dialog v-model="openErrorUserDialog" :persistent="isSessionExpired" width="500" color="error" class="errorDialog">
        <v-card  style="border-radius:15px; height:100%; padding:20px;">
            <v-card-title class="text-center flex-column justify-center">
                <v-btn v-if="!isSessionExpired" small @click="openErrorUserDialog = false" text style="position:absolute; top:10px; right:0">
                    <v-icon>$close</v-icon>
                </v-btn>
                <v-img
                    :src= "publicPath+'img/warning.png'"
                    height="120"
                    max-width="120"
                    contain
                ></v-img>
                <h3 class="black--text" style="font-size:20px;">{{$t('warning')}}</h3>
            </v-card-title>
            <v-card-text class="text-center" style="color:#000; font-size:18px;">                
                <span v-if="$store.state.errorObject.errorMessage && $store.state.errorObject.errorMessage.hasOwnProperty('text')">
                    {{$t($store.state.errorObject.errorMessage.text, { num : ($store.state.errorObject.errorMessage.id), module : $store.state.errorObject.errorMessage.module })}}
                </span>
                <span v-else>
                    {{$t($store.state.errorObject.errorMessage)}}
                </span>
            </v-card-text>
            <v-card-actions v-if="$store.state.errorObject.link" class="text-center justify-center mb-2">
                <v-btn depressed class="bg-gradient" @click="goToLink">{{$t('configuration')}}</v-btn>
            </v-card-actions>
            <v-card-actions v-else-if="isSessionExpired" class="text-center justify-center mb-2">
                <v-btn depressed class="error" @click="reloadWindow">{{$t('reconnectToApp')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name:"ErrorUserDialogComponent",
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        isSessionExpired() {
            if(this.$store.state.errorObject && this.$store.state.errorObject.errorMessage && this.$store.state.errorObject.errorMessage == "security auth failed"){
                return true
            }
            return false
        },
        openErrorUserDialog: { 
            // getter
            get: function () {
                return this.$store.state.errorUserDialog
            },
            // setter
            set: function (newValue) {
                this.$store.dispatch('REMOVE_ERROR', {})
            }
        }
    },
    methods: {
        reloadWindow(){
            window.location.reload()
        },
        goToLink(){
            let link = this.$store.state.errorObject.link;
            this.$store.dispatch('REMOVE_ERROR', {})
            this.$router.push(link)
        }
    },
}
</script>
<style lang="scss">
    .errorDialog {
        z-index: 1000;
        border-radius: 15px;
    }
    .errorTitle {
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
</style>