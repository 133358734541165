
const state = () => ({
    todos: [],
})

const mutations = {
    setTodosData(state, value){
        state.todos = value;
    },
}

const actions = {

}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}