const state = () => ({
    message: "",
    type: "",
    timeout: "",
})

const mutations = {
    setAlert(state, payload){
        state.message = payload.message;
        state.type = payload.type;
        if(payload.timeout) {
            setTimeout(() => {
                state.message = "";
                state.type = "";
            }, payload.timeout)
        }
    }
}


const actions = {
    setAlert({commit}, payload) {
        commit("setAlert", payload);
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
