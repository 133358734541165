import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path: '/landings-page',
        meta: { breadCrumb: 'landings-pages', titleDocument: "landingsPage", subMenu: false},
        component: function () {
            return import(/* webpackChunkName: "form-operations" */ '../../modules/landingpage2/views/Landing.vue')
        },
        children: [
            {
                path: '',
                name: "LandingPageList",
                meta: { title:'LandingList', breadCrumb: 'edit', titleDocument: "landingDetail", subMenu: false },
                component: function () {
                    return import(/* webpackChunkName: "template" */ '../../modules/landingpage2/views/landingPageList.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })         
                },
            }
        ]
    }
]