
const state = () => ({
    openExchangeDrawer: false,
    canalForDrawer: 'EMAIL',
    exchangeForDrawer: {},
    superleadForDrawer: {},
})

const mutations = {
    setOpenExchangeDrawer(state, value) { 
        state.openExchangeDrawer = value;
    },
    setCanalForDrawer(state, value) { 
        state.canalForDrawer = value;
    },
    setExchangeForDrawer(state, value) { 
        state.exchangeForDrawer = value;
    },
    setSuperleadForDrawer(state, value) { 
        state.superleadForDrawer = value;
    },
}

const actions = {
    SET_OPEN_EXCHANGE_DRAWER({ commit }, payload) {
        commit("setOpenExchangeDrawer", payload.value);
        if(payload.canal) commit("setCanalForDrawer", payload.canal);
        if(payload.exchange) commit("setExchangeForDrawer", payload.exchange);
        if(payload.superlead) commit("setSuperleadForDrawer", payload.superlead);
    },
    SET_CANAL_FOR_DRAWER({ commit }, payload) {
        commit("setCanalForDrawer", payload.value);
    },
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}