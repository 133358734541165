
export default [
    {
        path: 'exchanges',
        redirect: { name: 'ExchangesList' },
        meta: { breadCrumb: "exchangesList", titleDocument: "exchangesList", rights: [] },
        component: function () {
          return import(/* webpackChunkName: "exchangesList" */ '../../modules/exchanges/views/Exchange.vue')
        },
        children: [
            {
                path:'',
                name:'ExchangesList',
                meta: { title:'inbox', titleDocument: "exchangesList", rights: [] },
                component: function () {
                    return import(/* webpackChunkName: "exchangesList" */ '../../modules/exchanges/views/ExchangeList.vue')
                },
            },
        ]
      },
]