import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/generic/auth";
import GenericDataService from "@/services/GenericDataService";
import OperationStore from "@/modules/operations/OperationStore";
import ContactStore from "@/modules/contacts/ContactStore";
import EntitiesStore from "@/modules/entities/EntitiesStore";
import MissionsStore from "@/modules/missions/MissionsStore";
import TodoStore from "@/modules/todos/TodoStore";
import LeadStore from "@/modules/leads/LeadStore";
import FormStore from "@/modules/forms/FormStore";
import ExchangeStore from "@/modules/exchanges/ExchangeStore";
import UserStore from "@/modules/users/UserStore";
import AdministrationStore from "@/modules/administration/AdministrationStore";
import CampaignStore from "@/modules/campagnes/CampaignStore";
import TemplateStore from "@/modules/templates/TemplateStore";
import settings from "@/store/generic/settings";
import alerts from "@/store/generic/alerts";
import history from "@/store/generic/history";
import forms from "@/store/generic/forms";
import BaseStore from '@/modules/base/BaseStore';
import SegmentationStore from '@/modules/segmentations/SegmentationStore'
import AgendaStore from '@/modules/agenda/AgendaStore'
import BrandStore from '@/modules/brand/BrandStore'
import WebsiteStore from '@/modules/website/WebsiteStore'
import LibraryStore from '@/modules/library/LibraryStore'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false,
    loaderDashboard: false,
    logo:
      "https://cdn.dev.media.upyourbizz.com/data/DEMO/95/33/c4/94874f2aa3-161182099395.png?option=x40",
    favicon: "",
    moduleSettings: [],
    moduleSelect: [],
    nameSelect: [],
    snackbars : [],
    errorDialog: false,
    errorUserDialog: false,
    errorObject:{},
    workflowName: '',
    operationTypeName: ""
  },
  mutations: {
    AddSnack (state, value){
      state.snackbars.unshift(value)
    },
    RemoveSnack(state, value){
      setTimeout(() => {
        state.snackbars.pop();
      }, 4000);
    },
    RemoveSnackDirect(state, value){
        state.snackbars.pop();
    },
    setLoader(state, value) {
      state.loader = value;
    },
    setLoaderDashboard(state, value) {
      state.loaderDashboard = value;
    },
    setLogos(state, payload) {
      state.logo = payload.logo_menu;
      state.favicon = payload.favicon;
    },
    setModulesSettings(state, payload) {
      let modules = [];
      let names = [];
      payload.forEach((element) => {
        if (!modules.includes(element.module)) {
          modules.push(element.module);
        }
        if (!names.includes(element.name)) {
          names.push(element.name);
        }
      });
      state.moduleSettings = payload;
      state.moduleSelect = modules.sort();
      state.nameSelect = names;
    },
    AddError(state, payload){
      if(payload.type && payload.type == 'user'){
        state.errorUserDialog = true // TODO CHANGE popuyp pour erreur 'user' : erreur de config, etc..
      } else {
        state.errorDialog = true
      }
      
      state.errorObject = payload
    },
    RemoveError(state, payload){
      state.errorUserDialog = false
      state.errorDialog = false
      state.errorObject = {}
    },
    setWorkflowName(state, value){
      state.workflowName = value
    },
    setOperationTypeName(state, value){
      state.operationTypeName = value
    },
  },
  actions: {
    SET_OPERATION_TYPE_NAME({commit}, value){
      commit('setOperationTypeName', value)
    },
    SET_WORKFLOW_NAME({commit}, value){
      commit('setWorkflowName', value)
    },
    ADD_ERROR({commit}, payload){
      commit('AddError', payload)
    },
    REMOVE_ERROR({commit}, payload){
      commit('RemoveError', payload)
    },
    ADD_SNACK({commit}, payload){
      commit('AddSnack', payload)
    },
    REMOVE_SNACK({commit}, payload){
      commit('RemoveSnack')
    },
    REMOVE_SNACK_DIRECT({commit}, payload){
      commit('RemoveSnackDirect')
    },
    SET_LOADER_DASHBOARD({ commit }, payload) {
      commit("setLoaderDashboard", payload);
    },
    SET_LOADER({ commit }, payload) {
      commit("setLoader", payload);
    },
    async GET_DATA({ commit }, payload) {
      try {
        const response = await GenericDataService.getData(payload.url);

        // console.log("--------------------------------");
        // console.log(payload.url);
        // console.log(response);

        if (response.data.success) {
          commit(payload.mutation, response.data.data);
        }
      } catch (error) {
        //router.push({name: "Error404"})
        console.log('erreur', error);
      }
    },
    async SET_DATA({ commit }, payload) {
      try {
        const response = await GenericDataService.setData(
          payload.url,
          payload.data
        );

        // console.log("--------------------------------");
        // console.log(payload);
        // console.log(response);

        if (response.data.success) {
          if (payload.mutation && payload.mutation != "") {
            commit(payload.mutation, response.data.data);
          }
          return response;
        }
      } catch (error) {
        //router.push({name: "Error404"})
        throw error;
      }
    },
  },
  getters: {},
  modules: {
    base: BaseStore,
    auth: auth,
    operation: OperationStore,
    contact: ContactStore,
    todo: TodoStore,
    lead: LeadStore,
    form: FormStore,
    exchange: ExchangeStore,
    user: UserStore,
    administration: AdministrationStore,
    settings: settings,
    alerts: alerts,
    history: history,
    entities: EntitiesStore,
    missions: MissionsStore,
    campaign: CampaignStore,
    template: TemplateStore,
    forms: forms,
    segmentation: SegmentationStore,
    agenda: AgendaStore,
    brand: BrandStore,
    website: WebsiteStore,
    library: LibraryStore,
  },
});
