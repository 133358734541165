import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Keycloak from 'keycloak-js'
import DatetimePicker from 'vuetify-datetime-picker'
import UpYourTimeline from '@stibo/timeline-uyb'
import '@stibo/timeline-uyb/dist/upyourtimeline.css'
import {i18n} from './i18n'
import {func} from './functions'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/mode/css/css.js'
import VueDragscroll from 'vue-dragscroll'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import GenericDataService from '@/services/GenericDataService'

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.config.productionTip = false


Vue.use(VueDragscroll)
Vue.use(VueCodemirror);
Vue.use(UpYourTimeline);
Vue.use(DatetimePicker)
Vue.use(VueLodash, { lodash: lodash })
Vue.prototype.$func = func;
Vue.use(Donut);
Vue.directive('add-class-hover', {
  bind(el, binding, vnode) {    
    const { value="" } = binding;
    el.addEventListener('mouseenter',()=> {
        el.classList.add(value)
    });
    el.addEventListener('mouseleave',()=> {
        el.classList.remove(value)
    });
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseenter', ()=> {
      el.classList.add(value)
  });
    el.removeEventListener('mouseleave', () => {
      el.classList.remove(value)
    })
  }
})

let initOptions = {
  url: process.env.VUE_APP_SSO_URL, realm: process.env.VUE_APP_SSO_REALM, clientId: process.env.VUE_APP_SSO_CLIENT, onLoad: 'login-required', checkLoginIframe: false
}
let keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    new Vue({
      el: '#app',
      vuetify,
      i18n,
      router,
      store,
      render: h => h(App)
    })
  }
    let payload = {
      idToken: keycloak.idToken,
      accessToken: keycloak.token
    }

    if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
      store.commit("auth/setAuth", payload);
    }
    else {
    }

  //Token Refresh
    setInterval(() => {
      keycloak.updateToken(110).then((refreshed) => {
        if (refreshed) {

          let payload = {
            idToken: keycloak.idToken,
            accessToken: keycloak.token
          }
          store.commit("auth/setAuth", payload);
        } else {
           // + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        //Vue.$log.error('Failed to refresh token');
      });
    }, 6000)

  }).catch(() => {
    //Vue.$log.error("Authenticated Failed");
});
