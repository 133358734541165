import OperationDataService from '@/modules/operations/OperationDataService'

const state = () => ({
    operations: {},
    dashboard: [],
    operationCloseForm: false,
    opeDateChangeForm: false,
    opeDetailPopup: false,
    sendReportDialog: false,
})

const mutations = {
    setDashboardData(state, value){
        state.dashboard = value
    },
    setOperationsData(state, value){
        state.operations = value;
    },
    setOperationsCloseForm(state, value){
        state.operationCloseForm = value;
    },
    setOpeDateChangeForm(state, value){
        state.opeDateChangeForm = value;
    },
    setOperationsDetailPop(state, value){
        state.opeDetailPopup = value;
    },
    setSendReportDialog(state, value){
        state.sendReportDialog = value;
    }
}

const actions = {
    SET_OPECLOSE_FORM({ commit }, value) {
        commit("setOperationsCloseForm", value)
    },
    SET_OPEDATECHANGE_FORM({ commit }, value) {
        commit("setOpeDateChangeForm", value)
    },
    async getDashboardData({commit}, url) {
        try {
            const response = await OperationDataService.getData(url);
            if(response.data.success){
              commit('setDashboardData', response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    },
    SET_OPE_DATA({commit}, value){
        commit("setOperationsData", value)
    },
    SET_OPEDETAIL_POPUP({ commit }, value) {
        commit("setOperationsDetailPop", value)
    },
    SET_SEND_REPORT_DIALOG({ commit }, value) {
        commit("setSendReportDialog", value)
    },
}

const getters = {
    getOperationNameById: (state) => (operation_id) => {
        let operation = state.operations.find((e) => e.id == operation_id)
        return operation.name
    }
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}