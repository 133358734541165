//import '@fortawesome/fontawesome-pro/css/all.min.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {CUSTOM_ICONS} from './fontawesomeIcons';
import VStepperStepIcon from '@/components/extended/VStepperStepIcon'
import VStepperIcon from '@/components/extended/VStepperIcon'

Vue.use(Vuetify, {
  components: {
    VStepperIcon,
    VStepperStepIcon,
  },
});

export default new Vuetify({

    icons: {
        iconfont: 'Svg',
        values: CUSTOM_ICONS,
    },
    theme: {
        themes: {
          light: {
            primary: '#161459', // #E53935
            primarytext: '#FFFFFF',
            secondary: '#5f04ff',
            pink: '#ff2987',
            purple: '#6208ff',
            purple2: "#8336ff",
            orange: "#27c7d4",
            cyan :"#f96b2b",
            error: "#d9544f",
            success:"#5bb85d",
            info: "#347ab6",
            warning:"#efad4d",
            bg:"#FFFFFF",
            lightgrey: "#f2f2f2",
            extralightgrey: "#f9f9f9",
            mediumgrey: "#e6e6e6",
            darkgrey: "#7a7879",
            nearblack:"#4a4a4c",
            state_todo:"#efad4d",
            state_before: '#161459', 
            state_suffer:"#d9544f",
            state_fail: "#000000",
            state_success:"#5bb85d",
            pale_purple: "#efedff",
            gold:"#E3C034",
            silver: "#B7B7B7",
            bronze:"#CCA76E",
            lightblue: "#436E9E",
            darkblue: "#1E4169",
            mediumblue : "#294f79"
          },
          dark: {
                primary: "#09577d", // #E53935
                primarytext: '#FFFFFF',
                error: "#d9544f",
                success:"#5bb85d",
                info: "#347ab6",
                warning:"#efad4d",
                bg:"#1E1E1E",
                lightgrey: "#424242",
                state_todo:"#efad4d",
                state_before: '#161459', 
                state_suffer:"#d9544f",
                state_fail: "#000000",
                state_success:"#5bb85d",
                lightblue: "#436E9E",
                darkblue: "#1E4169",
                mediumblue : "#294f79"
          }
        },
        options: {
          customProperties: true
        },
      },
});
