import GenericDataService from '@/services/GenericDataService'
import vuetify from '@/plugins/vuetify'

const state = () => ({
    availableLangs:"",
})

const mutations = {
    setAvailableLangs(state, payload){
        state.availableLangs = payload
    }
}


const actions = {
    async SET_LANGS({commit}, payload){
        try {
          const response = await GenericDataService.getData('/ns/i18n/languages');
          commit('setAvailableLangs', response.data.data)
        } catch (error) {
          //router.push({name: "Error404"})
        }
    },
    async SET_DEFAULT_THEME({commit}, payload){
        await GenericDataService.setData('myprofile/setParameters?key=theme&setAsDefault=1', payload)
            .then((response) => {
                commit('auth/setColorForUser', response.data.data.theme, { root: true })
                commit('setLogos', response.data.data.theme, { root: true })
            })
            .catch((error) => {
                console.error(error)
            })   
    },
    async SET_PARAMETERS_DATATABLE({commit, dispatch}, payload) {
        await GenericDataService.setData('myprofile/setParameters?key='+payload.key, payload.data)
            .then((response) => {
            })
    },
    async SET_PARAM({commit, dispatch}, payload){
        await GenericDataService.setData('myprofile/setParameters?key='+payload.key, payload.data)
            .then((response) => {
            })
    }

}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}
