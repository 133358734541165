
const state = () => ({
    contacts: [],
    contact: {},
    items: [],
    points: [],
    todos: [],
    requests: [],
    meetings: [],
    blacklistForm: false,
    addForm: false,
    addRdv: false,
    refreshContactDetailTodo: false,
    tabId:0
})

const mutations = {
    setContactsData(state, value){
        state.contacts = value;
    },
    setContactData(state, value){
        state.contact = value;
    },
    addContactData(state, value){
        state.contacts.data.push(value);
    },
    removeContactData(state, value){
        state.contacts = state.contacts.filter((e) => e.id !== value.deleted);
    },
    setItemsData(state, value){
        state.items = value;
    },
    setPointsData(state, value){
        state.points = value;
    },
    setTodosData(state, value){
        state.todos = value;
    },
    setRequestsData(state, value){
        state.requests = value;
    },
    setMeetingsData(state, value){
        state.meetings = value.data;
    },
    openBlacklistForm(state, value){
        state.blacklistForm = value
    },
    openAddForm(state, value){
        state.addForm = value
    },
    setAddRdv(state, value){
        state.addRdv = value
    },
    refreshContactDetailTodo(state, value){
        state.refreshContactDetailTodo = value
    },
    setTabId(state, value){
        state.tabId = value
    }
}

const actions = {
    SET_CONTACT_DATA({ commit }, value) {
        commit("setContactData", value)
    },
    OPEN_BLACKLIST_FORM({ commit }, value) {
        commit("openBlacklistForm", value)
    },
    OPEN_ADD_FORM({ commit }, value) {
        commit("openAddForm", value)
    },
    SET_ADD_RDV({commit}, value){
        commit("setAddRdv", value)
    },
    REFRESH_CONTACTDETAIL_TODO({commit}, value){
        commit("refreshContactDetailTodo", value)
    },
    SET_TAB_ID({commit}, value){
        commit("setTabId", value)
    },
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}